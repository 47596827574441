section.feedback-section {
  background: linear-gradient(
    89.88deg,
    rgba(196, 196, 196, 0) 34.54%,
    rgba(210, 213, 233, 0.220665) 42.46%,
    rgba(154, 153, 221, 0.452021) 50.77%,
    #727ed1 70.44%,
    #434c8a 99.92%
  );
  padding-bottom: 39px;
  .container {
    padding-top: 41px;
    h3 {
      @include titleH2(#2c2e43, 386px);
    }
    .feedback-contents {
      display: flex;
      flex-direction: row;
      gap: 100px;
      @media (max-width: 660px) {
        gap: 20px;
      }
      @media (max-width: 578px) {
        gap: 0px;
      }
      @media (max-width: 550px) {
        flex-direction: column;
        gap: 0px;
      }
      .feedback__form {
        @media (max-width: 550px) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        &__descr {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          padding-top: 35px;
          padding-bottom: 40px;
          max-width: 518px;
        }
        form {
          .input-name {
            position: relative;
            margin-bottom: 34px;
            .input-text {
              position: absolute;
              top: -10px;
              left: 25px;
              font-family: Montserrat;
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              line-height: 142.69%;
              display: flex;
              align-items: center;
              color: #030303;
              background-color: #fff;
              padding: 0 9px;
            }
            input {
              border: 1px solid #a166aa;
              box-sizing: border-box;
              border-radius: 6px;
              width: 312px;
              height: 53px;
              padding-left: 20px;
              &::placeholder {
                font-family: Montserrat;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 142.69%;
                display: flex;
                align-items: center;
                color: #9a9a9a;
              }
              &:focus {
                outline: 0;
              }
            }
          }
          .input-number {
            position: relative;
            .input-text {
              position: absolute;
              top: -10px;
              left: 25px;
              font-family: Montserrat;
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              line-height: 142.69%;
              display: flex;
              align-items: center;
              color: #030303;
              background-color: #fff;
              padding: 0 9px;
            }
            input {
              border: 1px solid #a166aa;
              box-sizing: border-box;
              border-radius: 6px;
              width: 312px;
              height: 53px;
              padding-left: 20px;
              &::placeholder {
                font-family: Montserrat;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 142.69%;
                display: flex;
                align-items: center;
                color: #9a9a9a;
              }
              &:focus {
                outline: 0;
              }
            }
          }
          button.btn {
            margin-top: 65px;
            @include buttonBtn(260px, 14px 63px);
            @media (max-width: $xs) {
              margin: 30px auto 0;
              display: flex;
            }
          }
        }
      }
      .feedback__img {
        position: relative;
        @media (max-width: $xs) {
          display: flex;
          margin: 0 auto;
        }
        img {
          margin-top: -40px;
          @media (max-width: $lg) {
            width: calc(525px * 0.9);
            height: calc(510px * 0.9);
            margin-bottom: calc(510px * 0.1);
          }
          @media (max-width: 1128px) {
            width: calc(525px * 0.8);
            height: calc(510px * 0.8);
            margin-bottom: calc(510px * 0.2);
          }
          // @media (max-width: $md) {
          //   width: calc(525px * 0.7);
          //   height: calc(510px * 0.7);
          //   margin-bottom: calc(510px * 0.2);
          // }
          @media (max-width: 990px) {
            width: calc(525px * 0.7);
            height: calc(510px * 0.7);
            margin-bottom: 20px;
            margin-top: calc(510px * 0.01);
          }
          @media (max-width: 870px) {
            width: calc(525px * 0.65);
            height: calc(510px * 0.65);
            margin-bottom: 20px;
            margin-top: calc(510px * 0.01);
          }
          @media (max-width: $sm) {
            width: calc(525px * 0.6);
            height: calc(510px * 0.6);
            margin-bottom: 20px;
            margin-top: calc(510px * 0.01);
          }
          @media (max-width: 790px) {
            width: calc(525px * 0.55);
            height: calc(510px * 0.55);
            margin-bottom: 20px;
            margin-top: calc(510px * 0.1);
          }
          @media (max-width: 775px) {
            width: calc(525px * 0.5);
            height: calc(510px * 0.5);
            margin-bottom: 20px;
            margin-top: calc(510px * 0.1);
          }
          @media (max-width: 706px) {
            width: calc(525px * 0.4);
            height: calc(510px * 0.4);
            margin-bottom: 20px;
            margin-top: calc(510px * 0.1);
          }
          @media (max-width: $xs) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
