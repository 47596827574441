section.offer {
  position: relative;
  z-index: 0;
  background: linear-gradient(268.14deg, #424b8a 6.52%, #d9f0ff 98.43%);
  &::after {
    position: absolute;
    z-index: -2;
    width: 100%;
    height: 100%;
    clip-path: polygon(0 83%, 100% 35%, 100% 100%, 0 100%);
    bottom: 0;
    right: 0;
    left: 0;
    content: "";
    background: linear-gradient(269.69deg, #434c8a -16.91%, #d4ebfb 99.33%);
  }
  &::before {
    position: absolute;
    z-index: -3;
    width: 100%;
    height: 124px;
    clip-path: polygon(0 0, 0 100%, 100% -60%);
    bottom: -68px;
    right: 0;
    left: 0;
    content: "";
    background: rgba(67, 76, 138, 1);
    @media (max-width: $xs) {
      clip-path: polygon(0 0, 0 70%, 49% 0%);
    }
  }
  .container {
    display: flex;
    @media (max-width: 706px) {
      flex-wrap: wrap;
    }
    .contents {
      display: flex;
      flex-direction: column;
      @media (max-width: $xs) {
        align-items: center;
      }
      &__subtitle {
        max-width: 422px;
        margin-top: 45px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #ffffff;
        @media (max-width: $sm) {
          margin-top: 15px;
        }
        @media (max-width: $xs) {
          margin-top: 20px;
          align-self: flex-start;
          max-width: 254px;
        }
      }
      &__title {
        h1 {
          font-family: Nunito;
          font-style: normal;
          font-weight: 800;
          font-size: 40px;
          line-height: 42px;
          color: #2c2e43;
          margin-top: 12px;
          @media (max-width: 706px) {
            min-width: auto;
          }
          @media (max-width: $xs) {
            font-size: 34px;
            line-height: 40px;
          }
        }
      }
      &__descr {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 29px;
        color: #2c2e43;
        margin-top: 25px;
        max-width: 362px;
        @media (max-width: $xs) {
          font-size: 16px;
          line-height: 22px;
        }

        div.text__left {
          text-align: left;
        }
        div.text__right {
          text-align: right;
        }
      }
      button.btn {
        @include buttonBtn(235px, 17px 54px 20px 55px);
        margin-top: 35px;
      }
    }
    .picture {
      display: flex;
      @media (max-width: 706px) {
        flex: 1;
        align-items: center;
        justify-content: center;
      }
      flex-direction: column;
      img {
        margin-left: -170px;
        pointer-events: none;
        z-index: -1;
        @media (max-width: $lg) {
          width: calc(841px * 0.9);
          height: calc(464px * 0.9);
          margin-top: calc(464px * 0.1);
        }
        @media (max-width: 1128px) {
          width: calc(841px * 0.8);
          height: calc(464px * 0.8);
          margin-top: calc(464px * 0.2);
        }
        @media (max-width: $md) {
          width: calc(841px * 0.7);
          height: calc(464px * 0.7);
          margin-top: calc(464px * 0.3);
        }
        @media (max-width: 950px) {
          width: calc(841px * 0.6);
          height: calc(464px * 0.6);
          margin-top: calc(464px * 0.4);
        }
        @media (max-width: 870px) {
          width: calc(841px * 0.55);
          height: calc(464px * 0.55);
          margin-top: calc(464px * 0.45);
        }
        @media (max-width: $sm) {
          width: calc(841px * 0.5);
          height: calc(464px * 0.5);
          margin-top: calc(464px * 0.5);
        }
        @media (max-width: 790px) {
          width: calc(841px * 0.45);
          height: calc(464px * 0.45);
          margin-top: calc(464px * 0.5);
        }
        @media (max-width: 775px) {
          width: calc(841px * 0.4);
          height: calc(464px * 0.4);
          margin-top: calc(464px * 0.5);
        }
        @media (max-width: 706px) {
          margin-left: 0px;
          width: 100%;
          height: 100%;
          margin-top: 10px;
        }
      }
    }
  }
}
