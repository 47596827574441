section.header-title {
  background: linear-gradient(88.76deg, #d7eefe 1.43%, #444d8a 99.18%);
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-top: 42px;
    padding-bottom: 59px;
    gap: 30px;
    @media (max-width: $xs) {
      flex-direction: column-reverse;
    }
    .contents-title {
      display: flex;
      flex-direction: column;
      padding-right: 20px;
      @media (max-width: $lg) {
        padding-right: 0px;
      }
      h1 {
        font-family: Nunito;
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        line-height: 105.9%;
        color: #ffffff;
      }
      .subtitle {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 29px;
        color: #2c2e43;
        padding-top: 14px;
        max-width: 716px;
        @media (max-width: $md) {
          font-size: 20px;
          line-height: 27px;
        }
        @media (max-width: $md) {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }
}
