@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/base/_01_normalize.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/base/_mixins.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/base/_vars.scss";

@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_about-page.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_accordion.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_advantage.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_best-videos.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_callbackForm.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_command-services.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_contact-us-page.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_faq-&-news.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_fonts.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_footer.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_global.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_header.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_home-feedback.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_home-offer.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_home-servises.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_inputs.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_modal.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_popup-form.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_portfolio-page.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_reviews.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_services-page.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_svg.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_title-header-section.scss";

@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/_local-fonts.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/base/_01_normalize.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/base/_mixins.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/base/_vars.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_about-page.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_accordion.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_advantage.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_best-videos.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_callbackForm.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_command-services.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_contact-us-page.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_faq-&-news.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_fonts.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_footer.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_global.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_header.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_home-feedback.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_home-offer.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_home-servises.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_inputs.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_modal.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_popup-form.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_portfolio-page.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_reviews.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_services-page.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_svg.scss";
@import "K:/OpenServer/domains/estruktura-film.by/wp-content/themes/src/scss/global/_title-header-section.scss";


@import "../../node_modules/swiper/swiper-bundle.min.css";
@include font-face("Montserrat", "Montserrat-Regular", 400);
@include font-face("Nunito", "Nunito-ExtraBold", 800);
@include font-face("Nunito", "Nunito-Regular", 400);

.container {
  max-width: 1108px;
  margin: 0 auto;
  @media (max-width: $lg) {
    max-width: 90%;
  }
  @media (max-width: $xs) {
    max-width: 97%;
  }
}
section.breadcrumbs {
  .container {
    .page-title-breadcrumbs {
      ol {
        position: relative;
        display: flex;
        flex-direction: row;
        margin-top: 40px;
        li,
        li a,
        li a span {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
        }
        li:first-child::before {
          position: absolute;
          top: -10px;
          left: 0;
          content: url(../img/home.webp);
        }
        // li:not(:first-child):not(:last-child)::after
        li {
          padding-right: 44px;
        }
        li:not(:last-child)::after {
          position: absolute;
          top: -4px;
          left: 109px;
          content: url(../img/arrow_breadcrumbs.webp);
        }
      }
      .assistive-text {
        visibility: hidden;
        height: 0px;
      }
    }
  }
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: url(../img/arrow_right.webp);
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: url(../img/arrow_left.webp);
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: -45px;
  left: auto;
  @media (max-width: 1115px) {
    right: 10px;
  }
  @media (max-width: $xs) {
    right: 3%;
  }
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: auto;
  right: 450px;
  @media (max-width: 1115px) {
    right: 425px;
  }
  @media (max-width: $xs) {
    right: 88%;
  }
}
.swiper-button-next,
.swiper-button-prev {
  top: 53%;
  @media (max-width: 1115px) {
    top: 40%;
  }
  @media (max-width: $xs) {
    top: 10%;
  }
}

.whatsapp-button {
  position: fixed;
  right: 13px;
  bottom: 90px;
  transform: translate(-50%, -50%);
  background: #25d366; /*цвет кнопки*/
  border-radius: 50%;
  width: 55px; /*ширина кнопки*/
  height: 55px; /*высота кнопки*/
  color: #fff;
  text-align: center;
  line-height: 53px; /*центровка иконки в кнопке*/
  font-size: 35px; /*размер иконки*/
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  &:before,
  &:after {
    content: " ";
    display: block;
    position: absolute;
    border: 50%;
    border: 1px solid #25d366;
    right: -20px;
    left: -20px;
    top: -20px;
    bottom: -20px;
    border-radius: 50%;
    animation: animate 1.5s linear infinite;
    opacity: 0;
    backface-visibility: hidden;
  }
  &.telegram {
    bottom: 220px;
    background: #0088cc;
    img {
      max-width: 32px;
    }
    &:before,
    &:after {
      border: 1px solid #0088cc;
    }
  }
  &.viber {
    bottom: 155px;
    background: #9b51e0;
    img {
      max-width: 32px;
    }
    &:before,
    &:after {
      border: 1px solid #9b51e0;
    }
  }
}

.whatsapp-button a {
  color: #fff;
}

.whatsapp-button:after {
  animation-delay: 0.5s;
}

@keyframes animate {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
@media (max-width: 800px) {
  .whatsapp-button {
    bottom: 5px;
    &.telegram {
      bottom: 70px;
    }
    &.viber {
      bottom: 135px;
    }
  }
}
.video > a > img {
  border-radius: 8px;
}
.video > a {
  display: block;
}

#videoModal {
  position: fixed;
  opacity: 0;
  transition: all 0.3s;
  z-index: -999;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  max-width: 100%;
  iframe {
    max-width: calc(100vw - 30px);
  }
  .close-reveal-modal {
    cursor: pointer;
    opacity: 0;
    transition: all 0.3s;
    color: #fff;
    font-size: 30px;
    line-height: 30px;
    position: absolute;
    top: 0;
    right: 0px;
    text-align: center;
    background-color: #000;
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }
  &.active {
    z-index: 999999;
    opacity: 1;
    transition: all 0.3s;
    .close-reveal-modal {
      opacity: 1;
      transition: all 0.3s;
    }
  }
}
