section.servises {
  background: linear-gradient(
    159.99deg,
    #434c8a 14.5%,
    #5a669c 45%,
    #cfe5f7 86.65%
  );
  clip-path: polygon(0 0, 100% 0, 100% 85%, 50% 100%, 0 85%);
  @media (max-width: $md) {
    clip-path: polygon(0 0, 100% 0, 100% 95%, 50% 100%, 0 95%);
  }
  .container {
    padding-top: 57px;
    h3 {
      font-family: Nunito;
      font-style: normal;
      font-weight: 800;
      font-size: 30px;
      line-height: 105.9%;
      color: #ffffff;
      text-align: center;
      margin-bottom: 49px;
      margin-top: 76px;
    }
    h2 {
      @include titleH2(#ffffff, 571px);
      margin-bottom: 35px;
    }
    .descr {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      max-width: 882px;
      margin-bottom: 34px;
    }
    .servises__items {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      @media (max-width: $md) {
        flex-direction: column;
        gap: 30px;
      }
      &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: rgba(255, 255, 255, 0.4);
        border: 1px solid #e8e8e8;
        box-sizing: border-box;
        box-shadow: 10px 10px 1px #c4c4c4;
        border-radius: 6px;
        width: 350px;
        @media (max-width: $lg) {
          width: 320px;
        }
        @media (max-width: 340px) {
          width: 300px;
          box-shadow: none;
        }
        h3 {
          font-family: Nunito;
          font-style: normal;
          font-weight: 800;
          font-size: 22px;
          line-height: 105.9%;
          color: #2c2e43;
          margin: 15px 0 11px;
          @media (max-width: $lg) {
            font-size: 20px;
            line-height: 100%;
            margin: 15px 20px 11px;
          }
        }
        .descr {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          color: #000000;
          padding: 0 36px;
          @media (max-width: 1239px) {
            padding: 0 25px;
          }
        }
        button.btn {
          @include buttonBtn(263px, 13px 44px);
          margin-bottom: 30px;
        }
      }
    }
    .how-working {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-bottom: 182px;
      @media (max-width: $md) {
        flex-direction: column;
        align-items: flex-start;
      }
      &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        &__number {
          font-family: Nunito;
          font-style: normal;
          font-weight: 800;
          font-size: 100px;
          line-height: 136px;
          text-align: center;
          color: #ffffff;
          margin-right: 16px;
        }
        &__descr {
          position: relative;
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #2c2e43;
          &::before {
            position: absolute;
            bottom: -50px;
            left: 0;
            content: url(../img/arrow.svg);
          }
          &.leta::before {
            position: absolute;
            bottom: -40px;
            left: 0;
            content: url(../img/line.svg);
          }
          &.leta::after {
            position: absolute;
            bottom: -40px;
            right: 40px;
            content: url(../img/check.svg);
          }
        }
      }
    }
  }
}
