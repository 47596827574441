*,
*::before,
*::after {
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: black;
}
ul,
li {
  margin: 0;
  list-style: none;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.flex {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.--just-space {
  justify-content: space-between;
}

.--just-center {
  justify-content: center;
}

.--just-end {
  justify-content: flex-end;
}

.--align-str {
  align-items: stretch;
}

.--align-center {
  align-items: center;
}

.--align-end {
  align-items: flex-end;
}

.--dir-col {
  flex-direction: column;
}

html {
  font-size: 16px;
}

html,
body {
  min-height: 100%;
}

body {
  font-size: 1rem;
}

ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
