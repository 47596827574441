.accordion {
  margin-top: 60px;
  .accordion__item {
    margin-bottom: 6px;
    .accordion__item-trigger {
      display: flex;
      max-height: 37px;
      align-items: center;
      cursor: pointer;
      background: #434c8a;
      padding: 10px 0;
      border-radius: 6px;
      .trigger__num {
        margin-right: 10px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.035em;
        color: #8547c6;
      }
      .trigger__text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #ffffff;
      }
      .trigger__img {
        position: relative;
        padding: 0 38px 0 13px;
        @media (max-width: 360px) {
          padding: 0 10px 0 13px;
        }

        .line1 {
          display: block;
          width: 12px;
          height: 2px;
          background: #ffba58;
          border-radius: 30%;
        }
        .line2 {
          display: block;
          width: 12px;
          height: 2px;
          background: #ffba58;
          margin-top: -2px;
          border-radius: 30%;
          transform: rotate(90deg);
        }
      }
    }
    .accordion__item-content {
      display: none;
      animation: fade 0.3s ease;
      .content__text {
        margin-top: 6px;
        padding: 11px 8px 15px 11px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        border: 1px solid #434c8a;
        box-sizing: border-box;
        border-radius: 6px;

        // &:first-child {
        //   margin-top: 0;
        // }
      }
    }
  }
}

.accordion__item-active {
  .accordion__item-content {
    display: block !important;
  }
  .line2 {
    transform: translate(0) !important;
    transition: 0.1s all ease;
  }
  .line1 {
    opacity: 0;
    transition: 0.1s all ease;
  }
}
