section.reviews {
  position: relative;
  z-index: 0;
  background: linear-gradient(268.14deg, #434c8a 6.52%, #d1e8f9 98.43%);
  background-repeat: no-repeat;
  background-position-y: -60px;
  &::before {
    position: absolute;
    left: 0;
    top: 83%;
    content: "";
    background: #444d8a;
    transform: matrix(1, 0, 0, -1, 0, 0);
    z-index: -1;
    clip-path: polygon(0 73%, 0 100%, 47% 89%);
    width: 100%;
    height: 100%;
    @media (max-width: 380px) {
      clip-path: polygon(0 75%, 0 100%, 83% 89%);
    }
  }
  &::after {
    position: absolute;
    right: 0;
    top: 40%;
    content: "";
    background: linear-gradient(269.64deg, #4a548f -16.9%, #cce2f5 99.25%);
    transform: matrix(1, 0, 0, -1, 0, 0);
    z-index: -2;
    clip-path: polygon(100% 35%, 0 56%, 100% 100%);
    width: 100%;
    height: 100%;
  }
  .container {
    padding-top: 74px;
    h2 {
      @include titleH2(#2c2e43, 374px);
      margin-bottom: 41px;
    }
    .contents {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 50px;
      @media (max-width: $sm) {
        flex-direction: column-reverse;
        gap: 20px;
      }
      &__img {
        img {
          margin-left: -40px;
          @media (max-width: $lg) {
            width: calc(645px * 0.9);
            height: calc(483px * 0.9);
            margin-top: calc(483px * 0.1);
          }
          @media (max-width: 1128px) {
            width: calc(645px * 0.8);
            height: calc(483px * 0.8);
            margin-top: calc(483px * 0.2);
          }
          // @media (max-width: $md) {
          //   width: calc(645px * 0.7);
          //   height: calc(483px * 0.7);
          //   margin-bottom: calc(483px * 0.2);
          // }
          @media (max-width: 990px) {
            width: calc(645px * 0.7);
            height: calc(483px * 0.7);
            margin-top: calc(483px * 0.3);
          }
          @media (max-width: $sm) {
            width: calc(645px * 0.6);
            height: calc(483px * 0.6);
            margin-top: 0;
            align-self: self-start;
          }
          @media (max-width: 350px) {
            width: calc(645px * 0.5);
            height: calc(483px * 0.5);
            margin-top: 0;
            align-self: self-start;
          }
        }
      }
      .sliders {
        width: 100%;
        .slide {
          display: flex;
          flex-direction: column;
          @media (max-width: $sm) {
            flex-direction: row;
            justify-content: center;
          }
          @media (max-width: $xs) {
            flex-direction: column;
            justify-content: center;
          }
          .avatar {
            align-self: flex-start;
            margin-bottom: 27px;
            @media (max-width: 1115px) {
              align-self: center;
            }
          }
          .text-review {
            align-self: flex-end;
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #ffffff;
            max-width: 357px;
            margin: 0 auto;
            @media (max-width: 1115px) {
              align-self: center;
            }
            p {
              padding-bottom: 30px;
            }
          }
          .person {
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            color: #ffffff;
            text-align: right;
            align-self: flex-end;
          }
        }
      }
    }
  }
}
