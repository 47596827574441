footer.footer {
  section.footer-info {
    padding-top: 60px;
    padding-bottom: 46px;
    background: #434c8a;
    .container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      @media (max-width: $xs) {
        flex-direction: column;
      }
      .footer-navi {
        display: flex;
        flex-direction: column;
        @media (max-width: $xs) {
          margin: 0 auto;
          max-width: 185px;
        }
        &__title {
          font-family: Nunito;
          font-style: normal;
          font-weight: 800;
          font-size: 12px;
          line-height: 16px;
          display: flex;
          align-items: center;
          max-width: 240px;
          color: #ffffff;
          padding-bottom: 9px;
        }
        &__list {
          a {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #ffffff;
            &:hover {
              color: #ffba58;
            }
          }
          li:nth-child(-n + 2) {
            padding-bottom: 10px;
          }
        }
      }
      .footer-contacts {
        display: flex;
        flex-direction: column;
        @media (max-width: $xs) {
          margin: 0 auto;
          padding-top: 30px;
          min-width: 185px;
        }
        &__title {
          font-family: Nunito;
          font-style: normal;
          font-weight: 800;
          font-size: 20px;
          line-height: 27px;
          color: #ffffff;
          padding-bottom: 15px;
        }
        &__list {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          a,
          span {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #ffffff;
            &:hover {
              color: #ffba58;
            }
          }
          a:nth-child(-n + 3) {
            padding-bottom: 7px;
          }
          div {
            padding-bottom: 7px;
          }
        }
      }
      .footer-adress {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @media (max-width: 645px) {
          padding-top: 30px;
        }
        @media (max-width: $xs) {
          margin: 0 auto;
          padding-top: 30px;
          min-width: 185px;
        }
        &__title {
          font-family: Nunito;
          font-style: normal;
          font-weight: 800;
          font-size: 20px;
          line-height: 27px;
          color: #ffffff;
          padding-bottom: 15px;
        }
        &__location {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #ffffff;
          display: flex;
          flex-direction: column;
          span:nth-child(-n + 3) {
            padding-bottom: 7px;
          }
        }
      }
      .footer-socials {
        display: flex;
        flex-direction: column;
        @media (max-width: 930px) {
          margin: 0 auto;
          padding-top: 30px;
        }
        &__title {
          font-family: Nunito;
          font-style: normal;
          font-weight: 800;
          font-size: 20px;
          line-height: 27px;
          color: #ffffff;
          padding-bottom: 15px;
        }
        &__icons {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          & > a {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
  section.footer-section {
    background: #030303;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      padding: 25px 0;
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
    }
  }
}
