section.section-faq-news {
  margin-top: 100px;
  padding-bottom: 70px;
  .container {
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: row;
    gap: 63px;
    @media (max-width: $md) {
      flex-direction: column-reverse;
      gap: 20px;
    }
    // &::before {
    //   position: absolute;
    //   z-index: 2;
    //   top: 0;
    //   right: 50%;
    //   content: "";
    //   width: 1px;
    //   height: 450px;
    //   background-color: #000000;
    //   @media (max-width: $md) {
    //     display: none;
    //   }
    // }
    .news {
      flex: 1;
      display: flex;
      flex-direction: column;
      h3 {
        font-family: Nunito;
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 105.9%;
        color: #2c2e43;
        padding-bottom: 59px;
      }
      &__items {
        display: flex;
        flex-direction: column;
        gap: 35px;
        &__item {
          display: flex;
          flex-direction: row;
          gap: 35px;
          @media (max-width: $xs) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;
            padding: 0 20px;
          }
          .news-img {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 253px;
            height: 149px;
            border-radius: 8px;
            & > img {
              display: flex;
              object-fit: contain;
              max-width: 100%;
              border-radius: 8px;
            }
          }
          .news-content {
            &__date {
              font-family: Montserrat;
              font-style: normal;
              font-weight: normal;
              font-size: 13px;
              line-height: 142.69%;
              display: flex;
              align-items: center;
              color: #9a9a9a;
              padding-top: 24px;
            }
            &__anons {
              font-family: Montserrat;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 17px;
              color: #000000;
              padding-top: 6px;
            }
            a {
              font-family: Montserrat;
              font-style: normal;
              font-weight: normal;
              font-size: 13px;
              line-height: 142.69%;
              display: flex;
              align-items: center;
              text-decoration-line: underline;
              color: #9a9a9a;
              padding-top: 8px;
            }
          }
        }
      }
    }
    .faq {
      flex: 1;
      h3 {
        font-family: Nunito;
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 105.9%;
        color: #2c2e43;
      }
      .accordion {
        max-width: 70%;
        margin: 60px auto 0 auto;
        @media (max-width: 870px) {
          max-width: 100%;
        }
      }
    }
  }
}
